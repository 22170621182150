import React, { useState,useContext,useEffect,useCallback } from "react"
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import DataStore from './stores/data'
import { observer } from 'mobx-react-lite'


import {
 Typography
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
 twineFrame:{
   width:'100%',
   height:'100vh',
   border:'none'
 }
}))

const Template = observer((props) => {
  const classes = useStyles()
  const { data } = props

  return (
   <iframe src={'/embed?shortid='+data.shortid} className={classes.twineFrame}></iframe>
  )
})



export default Template
