import React, { useEffect,useContext } from "react"
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import DataStore from './stores/data'
import { observer } from 'mobx-react-lite'
import { useTheme } from '@material-ui/core/styles'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Template from './Template'
import Draggable from 'react-draggable'

import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Paper,
  Tabs,
  Tab,
  Box,
  Typography,
  Toolbar,
  IconButton,
  Popover
} from '@material-ui/core'
import {
  red,deepPurple,deepOrange
} from '@material-ui/core/colors'
import {
  ExpandMore,Favorite,Share,MoreVert,Close,ZoomIn,ZoomOut,ZoomOutMap
} from '@material-ui/icons'

import './twine.css'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  spacing:{
    paddingTop:theme.spacing(6),
    paddingBottom:theme.spacing(6)
  },
  paddingTopBottom:{
    paddingTop:100,
    paddingBottom:100
  },
  [theme.breakpoints.down('sm')]:{
    paddingTopBottom:{
      paddingTop:30,
      paddingBottom:30
    }
  },
  appBar: {
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  code:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    whiteSpace:'pre-wrap',
    height:'100%',
    paddingBottom:theme.spacing(2),
    marginTop:56,
  },
  passage:{
    cursor:'pointer !important',
    "&:hover":{
      background:'#fcefe2 !important',
    }
  },
  circularArrow:{
    maxWidth:'100%',
    position:'absolute',
    top:-20,
    left:-20,
    width:50,
  },
  arrowEnd:{
    markerEnd:'url(#endarrow)'
  },
  arrowStart:{
    markerStart:'url(#startarrow)'
  },
  codePanel:{
    padding: theme.spacing(2),
    maxWidth:500,
  },
  appBar:{
    background:'white',
    color:'#000',
    height:56,
    top:'inherit'
  },
  closeButton:{
    marginRight:theme.spacing(2)
  },
  title:{
    flexGrow:1
  }
}))


const StoryViewer = observer(() => {
  const classes = useStyles()
  const { storyViewerUI,kiste } = useContext(DataStore)
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      kiste.setCurrentStoryViewer(null)
    }
  },[])

  return (
    <Dialog
      fullScreen={true}
      open={storyViewerUI.open}
      onClose={storyViewerUI.handleClose}
      aria-labelledby="responsive-dialog-title"
    >
     <AppBar className={classes.appBar} position="fixed">
      <Toolbar>
       <Typography variant="h6" className={classes.title}>
        {kiste.currentStoryViewer && kiste.currentStoryViewer.contentname}
       </Typography>
       <Typography variant="h6">
        {kiste.currentStoryViewer && kiste.currentStoryViewer.filename}
       </Typography>
      </Toolbar>
     </AppBar>
      {storyViewerUI.viewmode === storyViewerUI.viewmodes.CODE && <TwineEmbed />}
      {storyViewerUI.viewmode === storyViewerUI.viewmodes.GAME && <TwineStory />}
     <DialogContent />
    </Dialog>
  )
})

const TwineStory = observer(() => {
  const { kiste } = useContext(DataStore)
  const { currentStoryViewer } = kiste

  return (
    <Template data={currentStoryViewer} />
  )
})


const TwineEmbed = observer(() => {
  const classes = useStyles()
  const { story,kiste,storyViewerUI } = useContext(DataStore)
  const { currentStoryViewer } = kiste
  const { arrows } = currentStoryViewer

  const _showCode = (e,passage) => {
    currentStoryViewer.setActivePassage(passage)
    storyViewerUI.setCodeAnchorEl(e.currentTarget)
  }

  const frame = story.findOptimalIframeSize(currentStoryViewer.story.passages)
  const width = frame.width < window.innerWidth ? '100%' : (frame.width+(frame.width*0.1))+'px'
  const height = frame.height < window.innerHeight ? '100%' : (frame.height+(frame.height*0.1))+'px'

  return (
   <>
    <div id="storyEditView" style={{width,height,marginTop:20,overflow:'auto'}}>
      <div className="passages">
        {currentStoryViewer.story && currentStoryViewer.story.passages && currentStoryViewer.story.passages.map((p,index) => {
          const unit = 'px'
          return <div
            id={p.pid}
            key={p.pid}
            onClick={(e)=>_showCode(e,p)}
            className={`passage ${classes.passage}`}
            style={{left: p.left+unit, top:p.top+unit , width: p.width+unit, height: p.height+unit,overflow:(p.self?'inherit':'')}}>
            <div className="frame">
              <div className="tags">
              </div>
              <p className="title">
                {p.pid == currentStoryViewer.startnode && <><span className="badge-create"></span><br /></>}
                <span className="text">{p.name}</span>
              </p>
              <p className="excerpt">
                {p.code}
              </p>
           </div>
          </div>
        })}
       <Arrows arrows={arrows} />
      </div>
      <CodePanel />
    </div>
   </>
  )
})


const CodePanel = observer(() => {
  const classes = useStyles()
  const { storyViewerUI,kiste } = useContext(DataStore)
  const { codeAnchorEl,setCodeAnchorEl } = storyViewerUI
  const { currentStoryViewer } = kiste
  const { activePassage } = currentStoryViewer
  const createMarkup = () => { return {
    __html: activePassage.code };}

  return (
    <Popover
        open={Boolean(codeAnchorEl)}
        anchorEl={codeAnchorEl}
        onClose={()=>setCodeAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}

      >
      {activePassage && <pre className={`${classes.codePanel}`}>
        <code style={{whiteSpace:'break-spaces'}} dangerouslySetInnerHTML={createMarkup()} />
       </pre>
      }
      </Popover>
  )
})

const Arrows = observer((props) => {
  const classes = useStyles()
  const { arrows } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" className="links" style={{transform: 'scale(1)', width: '100%', height: '100%'}}>
     <defs>
      <marker id="startarrow" markerWidth="10" markerHeight="7" refX="3" refY="4" orient="auto">
       <polygon points="10 0, 10 7, 0 3.5" fill="#666" />
      </marker>
      <marker id="endarrow" markerWidth="10" markerHeight="7" refX="4" refY="4" orient="auto" markerUnits="strokeWidth">
       <polygon points="0 0, 10 3.5, 0 7" fill="#666" />
      </marker>
     </defs>
      {arrows && arrows.map((arrow,index) => {
          return <path
           key={'arrow-'+index}
           d={arrow.options.svgpath}
           className={`
             ${classes.arrowEnd}
             ${(arrow.options.showTail ? classes.arrowStart : '')}
           `}>
          </path>
      })}
    </svg>
  )
})







export default StoryViewer
