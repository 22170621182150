import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'



const DE = {
 translation:{
  twinebox:"TwineBox",
  forSchools:"für Schulen",
  introTeacher:"Mit diesem Dienst kannst Du Click-Stories Deiner Schülerinnen und Schüler in einer virtuellen Kiste sammeln und auf einen Blick ansehen.",
  makeYourOwn:"Eigene Kiste erstellen",
  createKiste:"Kiste erstellen",
  deleteKiste:"Kiste endgültig löschen",
  noMoreAsking:"es gibt keine weitere Nachfrage",
  nameYourKiste:"Name",
  yourLastBoxes:"Deine letzten Kisten",
  comment:"Kommentieren",
  enterPin:"PIN",
  enter:"Enter",
  emptyBox:"Deine Twinebox ist noch leer",
  update:"Aktualisieren",
  similar:"Ich hab da schon etwas Ähnliches gefunden",
  dragNDrop:"Geschichte hier fallen lassen oder klick :)",
  uploadNow:"Jetzt hochladen",
  create:"Neu anlegen",
  whoDidThis:"Wer hat die Geschichte erstellt?",
  overwrite:"Verwendest Du immer den gleichen Namen, wird Deine Geschichte aktualisiert.",
  foundArchive:"Du hast ein Archiv hochgeladen.",
  anArchive:"Ein Archiv enthält mehrere Geschichten, welche möchtest Du hochladen?",
  filename:"Name",
  or:"oder",
  noYay:"Hmm, das scheint keine Twinegeschichte zu sein",
  yay:"Yay, das hat wunderbar geklappt",
  uploadMore:"Weitere Geschichten hochladen?",
  playStory:"Geschichte erleben",
  viewStoryCode:"Code ansehen",
  close:"Schliessen",
  shareSuS:"um eine Geschichte in die Twinebox zu legen.",
  shareTeacher:"um die Twinebox jederzeit wieder anzuschauen.",
  visit:"Besuche",
  youAreHere:"Du bist gerade hier",
  andEnter:"und gib diese PIN ein",
  andMemorize:"merke Dir diese PIN",
  deleteStory:"Geschichte löschen",
  deleteStoryReally:"Lösch nur, die Geschichte kann ja wieder hochgeladen werden ;)",
  forTeachers:"Für Lehrpersonen",
  forPupils:"Für Schüler",
  doPublishDontArchive:"Wo bekomme ich die richtige Datei zum Hochladen?",
  uploadMyStory:"Meine Geschichte hinzufügen",
  forYou:"für Dich",
  forYourPupils:"Für deine Schüler"
 }
}


const resources = {
  de:DE
}


i18next
 .use(LanguageDetector)
 .use(initReactI18next)
 .init({
  resources,
  saveMissing: true,
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true
  }
 })



export default i18next
