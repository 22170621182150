import axios from 'axios'

class Ajax {
 //####################################################
 getTwineTemplate (cbSuccess,cbFail) {
   axios
    .post('/api/twinetemplate')
    .then(cbSuccess)
    .catch(cbFail)
 }
 validateKisten (data,cbSuccess,cbFail) {
   axios
    .post('/api/kisten/validate', data)
    .then(cbSuccess)
    .catch(cbFail)
 }

 deleteKiste (data,cbSuccess,cbFail) {
   axios
    .post('/api/kiste/delete', data)
    .then(cbSuccess)
    .catch(cbFail)
  }
 createKiste (name,cbSuccess,cbFail) {
   axios
    .post('/api/kiste/create', {
     name
    })
    .then(cbSuccess)
    .catch(cbFail)
  }
  getKiste (shortid,cbSuccess,cbFail) {
    axios
     .post('/api/kiste/get', {
      shortid
     })
     .then(cbSuccess)
     .catch(cbFail)
   }
   unlockKiste (pin,cbSuccess,cbFail) {
     axios
      .post('/api/kiste/unlock', {
       pin
      })
      .then(cbSuccess)
      .catch(cbFail)
    }
   addStoryComment (data,cbSuccess,cbFail) {
     axios
      .post('/api/story/comment', data)
      .then(cbSuccess)
      .catch(cbFail)
   }
   deleteStoryComment (data,cbSuccess,cbFail) {
     axios
      .post('/api/story/comment/delete',data)
      .then(cbSuccess)
      .catch(cbFail)
   }
   updateStoryLikes (data,cbSuccess,cbFail) {
     axios
      .post('/api/story/like', data)
      .then(cbSuccess)
      .catch(cbFail)
   }
   createStory (data,cbSuccess,cbFail) {
     axios
      .post('/api/story/create', data)
      .then(cbSuccess)
      .catch(cbFail)
   }
   getStory (shortid,cbSuccess,cbFail) {
     axios
      .post('/api/story/get',{
        shortid
      })
      .then(cbSuccess)
      .catch(cbFail)
   }
   deleteStory (data,cbSuccess,cbFail) {
     axios
      .post('/api/story/delete',data)
      .then(cbSuccess)
      .catch(cbFail)
   }
//####################################################
}



const AjaxClient = new Ajax()
export default AjaxClient
