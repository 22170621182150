import React, { useContext } from "react"
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import DataStore from './stores/data'
import { observer } from 'mobx-react-lite'

import {
  Grid,
  Container,
  TextField,
  Typography,
  Button,
  IconButton
} from '@material-ui/core'

import {
  ArrowBack
} from '@material-ui/icons'

import IMG_LOGO_TWINEBOX from 'media/img/TwineBoxLogo.png'

const useStyles = makeStyles(theme => ({
  makeNewContainer:{
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'center',
    minHeight:'100vh',
    textAlign:'center'
  },
  spacing:{
    margin:theme.spacing(2)
  },
  arrowBack:{
    position:'absolute',
    top:theme.spacing(2),
    left:theme.spacing(2)
  },
 mascot:{
   maxWidth:450,
   maxHeight:450
 }
}))

const MakeNewKiste = observer((props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { kiste,views,setView,addToLocalStorage } = useContext(DataStore)

  const _create = () => {
    kiste.create(() => {
      addToLocalStorage(kiste.shortid,kiste.name)
      props.history.push('/box')
      setView(views.KISTE)
    })
  }

  const _back = () => {
    props.history.push('/')
    setView(views.START)
  }

  return (
   <Grid container spacing={3}>
    <IconButton onClick={_back} className={classes.arrowBack}><ArrowBack /></IconButton>
    <Grid item xs={12}>
    <Container maxWidth="sm" className={classes.makeNewContainer}>
     <img src={IMG_LOGO_TWINEBOX} alt="Mascot" className={classes.mascot}/>
     <div className={classes.spacing} />
     <Typography variant="h5">{t('introTeacher')}</Typography>
     <div className={classes.spacing} />
     <TextField autoFocus onChange={kiste.setName} value={kiste.name} label={t('nameYourKiste')} variant="outlined" fullWidth/>
     <Button onClick={_create} disabled={!kiste.isValidName}>{t('createKiste')}</Button>
    </Container>
    </Grid>
  </Grid>
  )
})

export default MakeNewKiste
