import { createContext } from 'react'
import { makeAutoObservable,toJS,configure } from 'mobx'
import AjaxClient from 'api/ajax'
import { getBoxToBoxArrow } from 'perfect-arrows'
import ls from 'local-storage'
import { Base64 } from 'js-base64'
import seedrandom from 'seedrandom'


import IMG_BOX_CLOSED from 'media/img/Box_Closed.png'
import IMG_BOX_HALF from 'media/img/Box_Half.png'
import IMG_BOX_OPEN from 'media/img/Box_Open.png'
import IMG_TEST from 'media/img/TwineBoxClosed (1).png'

configure({
    enforceActions: "never",
})


class Data {
  constructor () {
    makeAutoObservable(this)
  }

  kiste = new Kiste(this)
  story = new Story(this)
  storyViewerUI = new StoryViewerUI(this)
  boxAnimationUI = new BoxAnimationUI()
  commentsUI = new CommentsUI()

  io = new IO(this.kiste,this.story,this.commentsUI)

  views = {
    START:1,
    MAKE_NEW_KISTE:2,
    KISTE:3
  }
  view = this.views.START
  setView = (view) => this.view = view

  addToLocalStorage = (num,name) => {
    if (!ls.get('tw-'+num)) {
      ls.set('tw-'+num,JSON.stringify({ pin:num, name }))
    }
  }

  removeFromLocalStorage = (num) => {
    ls.remove('tw-'+num)
  }

  readFromLocalStorage = () => {
    const keys = Object.keys(window.localStorage)
    let cached = []
    const off= keys.map(key => {
      if (key.startsWith('tw-')) {
        const entry = ls.get(key)
        if (entry) {
          cached.push(JSON.parse(entry))
        }
      }
    })
    return cached
  }

  validateCache = () => {
    const cached = this.readFromLocalStorage()
    if (cached && cached.length > 0) {
      AjaxClient.validateKisten(
        { kisten:cached },
        (res) => {
         if (res && res.data && res.data.success) {
           res.data.validation.map((entry) => {
             if (!entry.isValid) {
               this.removeFromLocalStorage(entry.shortid)
             }
           })
         }
        },
        () => {  }
      )
    }
  }

}

class BoxAnimationUI {
  constructor () {
    makeAutoObservable(this)
    this.switchTo(this.types.CLOSED)
  }
  types = {
    CLOSED:'CLOSED',
    HALF:'HALF',
    OPEN:'OPEN'
  }
  sources = {
    CLOSED:IMG_BOX_CLOSED,
    HALF:IMG_BOX_HALF,
    OPEN:IMG_BOX_OPEN
  }
  src = this.sources.CLOSED
  setSrc = (src) => this.src = src

  animations = {
    CLOSED:'shakeX',
    HALF:'tada',
    OPEN:''
  }
  animation = this.animations.CLOSED
  setAnimation = (a) => this.animation = a

  delays = {
    CLOSED:1,
    HALF:0,
    OPEN:0.5
  }
  delay = this.delays.CLOSED
  setDelay = (d) => this.delay = d

  switchTo = (type) => {
    this.setSrc(this.sources[type])
    this.setAnimation(this.animations[type])
    this.setDelay(this.delays[type])
  }

}

class IO {
  constructor (kiste,story,commentsUI) {
    makeAutoObservable(this)
    this.kiste = kiste
    this.story = story
    this.commentsUI = commentsUI
  }

  socket = null
  kiste = null
  commentsUI = null

  init = () => {
    if (this.socket) return

    this.socket = !this.socket ? window.io() : this.socket
    this.socket.on('joinKiste',() => {
      this.socket.emit('join',{ kistenId: this.kiste.shortid })
    })
    this.socket.on('supernova',() => {
      // TwineBox was deleted completely
      window.location.href = '/'
    })
    this.socket.on('updateStory',(msg) => {
      const { storyId } = msg
      if (storyId) {
       this.kiste.updateStory(storyId)
      }
    })
    this.socket.on('deleteStory',(msg) => {
      const { storyId } = msg
      if (storyId) {
       this.kiste.deleteStory(storyId)
      }
    })
    this.socket.on('comment',(msg) => {
      const { storyId,comment } = msg
      if (storyId && comment) {
       this.kiste.addComment(storyId,comment)
      }
    })
    this.socket.on('deleteComment',(msg) => {
      const { commentId,storyId } = msg
      if (commentId && storyId) {
       this.kiste.deleteComment(storyId,commentId,(updatetComments) => {
        this.commentsUI.setComments(updatetComments)
       })
      }
    })
    this.socket.on('likes',(msg) => {
      const { storyId,like,dislike } = msg
      if (storyId) {
       if (like) {
         this.kiste.likeStory(storyId)
       } else {
         this.kiste.dislikeStory(storyId)
       }
      }
    })
  }
}

class Kiste {
  constructor (main) {
    makeAutoObservable(this)
  }
  name = ""
  setName = (e) => this.name = e.target.value
  pin = null
  setPin = (pin) => this.pin = pin
  shortid = null
  setShortId = (id) => { this.shortid = id }
  stories = []
  clearStories = () => this.stories.length = 0
  addStory = (s) => this.stories.push(s)
  avatars = 108
  locked = false
  setLock = (v) => this.locked = v
  getNextAvatar = () => {
    this.avatars--
    return this.avatars
  }

  currentStoryViewer = null
  setCurrentStoryViewer = (sv) => this.currentStoryViewer = sv

  types = {
    SUS:1,
    TEACHER:2
  }
  type = this.types.TEACHER
  setType = (type) => this.type = type

  get isValidName () {
    return this.name !== '' && this.name.length > 4
  }

  findStoryById = (id) => {
    const s = this.stories.filter(story => story.shortid === id)
    return s && s[0] ? s[0] : null
  }

  deleteStory = (id) => {
    this.stories = this.stories.filter(s => s.shortid !== id)
  }

  addComment = (id,comment) => {
    this.stories.map(s => {
      if (s.shortid == id) {
        s.addComment(comment)
      }
    })
  }

  deleteComment = (id,commentId,cb) => {
    this.stories.map(s => {
      if (s.shortid == id) {
        s.deleteCommentLocally(commentId,cb)
      }
    })
  }

  likeStory = (id) => {
    this.stories.map(s => {
      if (s.shortid == id) {
        s.likeIt()
      }
      return s
    })
  }

  dislikeStory = (id) => {
    this.stories.map(s => {
      if (s.shortid == id) {
        s.dislikeIt()
      }
      return s
    })
  }


  updateStory = (id) => {
   AjaxClient.getStory(
     id,
     (res) => {
       if (res && res.data && res.data.success) {
         const storyViewer = this.findStoryById(res.data.story.shortid)
         let temp = this.stories
         if (storyViewer) {
           temp = this.stories.filter(story => story.shortid !== storyViewer.shortid)
         }
         temp.push(new StoryViewer(res.data.story))
         this.stories = temp
       }
     },
     () => {}
   )
  }

  update = (cb) => {
    let shortid = this.shortid
    let type = this.type

    if (shortid && type) {
      this.get(shortid,type,cb)
    }
  }

  get = (shortid,type,cb) => {
    if (shortid) {
      this.getKiste(shortid,type,cb)
    }
  }

  delete = () => {
    if (this.shortid) {
      AjaxClient.deleteKiste({ kistenId: this.shortid })
    }
  }

  getSeeder = (story) => {
    return seedrandom(story.filename)
  }


  randomAvatar = (story) => {
    const seeder = this.getSeeder(story)

    let rand = Math.floor(seeder() * 108) + 1

    if (rand.toString().length === 1 ) rand = '00'+rand
    else if (rand.toString().length === 2 ) rand = '0'+rand
    else if (rand.toString().length === 3 ) rand = rand

    // dirty fix
    if (rand.toString().length === 4) {
      rand = rand.substring(1, rand.toString().length)
    }

    return rand
  }

  randomRotation = (story) => {
    const seeder = this.getSeeder(story)
    return Math.ceil(seeder() * 20) * (Math.round(seeder()) ? 1 : -1)
  }

  getKiste = (shortid,type,cb) => {
    AjaxClient.getKiste(
      shortid,
      (res) => {
        if (res && res.data && res.data.success) {
          const kiste = res.data.kiste
          this.setName({target:{value:kiste.name}})
          this.setPin(kiste.pin)
          this.setShortId(kiste.shortid)
          this.setType(type)
          this.clearStories()


          // hack for Mike ;)
          if (kiste.pin === "486953") {
            this.setLock(true)
          }

          kiste.stories.map(story => {
            const avatar = this.randomAvatar(story)
            const rotation = this.randomRotation(story)

            this.addStory(new StoryViewer(story,avatar,rotation))
          })
        }
        cb()
      },
      () => {}
    )
  }

  create = (cb) => {
    if (this.isValidName) {
      AjaxClient.createKiste(
        this.name,
        (res) => {
         if (res && res.data && res.data.success) {
           this.get(res.data.shortid,this.types.TEACHER,cb)
         }
        },
        () => {}
      )
    }
  }
}

class CommentsUI {
  constructor () {
    makeAutoObservable(this)
  }

  open = false
  handleOpen = () => this.open = true
  handleClose = () => this.open = false

  comments = []
  owner = null
  content = null
  setData = (data) => {
    this.setComments(data.comments)
    this.setOwner(data.filename)
    this.setContent(data)
  }
  setComments = (comments) => this.comments = comments
  setOwner = (owner) => this.owner = owner
  setContent = (content) => this.content = content

}

class StoryViewerUI {
  constructor () {
    makeAutoObservable(this)
  }

  open = false
  handleOpen = () => this.open = true
  handleClose = () => this.open = false
  codeAnchorEl = null
  setCodeAnchorEl = (el) => this.codeAnchorEl = el

  viewmodes = {
    CODE:1,
    GAME:2
  }
  viewmode = this.viewmodes.CODE
  setViewmode = (mode) => this.viewmode = mode

  deleteModalOpen = false
  handleDeleteModalOpen = () => this.deleteModalOpen = true
  handleDeleteModalClose = () => this.deleteModalOpen = false

  uploadModalOpen = false
  handleUploadModalOpen = () => this.uploadModalOpen = true
  handleUploadModalClose = () => this.uploadModalOpen = false

  colors = {
    PINK:{
      background:'#a9147f1A',
      font:'inherit'
    }
  }
  getRandomColor = (seeder) => {
    const keys = Object.keys(this.colors)
    const randomKey = Math.floor(seeder() * keys.length)
    return this.colors[keys[randomKey]]
  }

  fixUmlaute = (value) => {
    value = value.replace(/ä/g, '&auml;');
    value = value.replace(/ö/g, '&ouml;');
    value = value.replace(/ü/g, '&uuml;');
    value = value.replace(/ß/g, '&szlig;');
    value = value.replace(/Ä/g, '&Auml;');
    value = value.replace(/Ö/g, '&Ouml;');
    value = value.replace(/Ü/g, '&Uuml;');
    return value
  }

}

class StoryViewer {
  constructor (story,avatar,rotation) {
    makeAutoObservable(this)
    this.filename = story.filename
    this.contentname = story.contentname
    this.startnode = story.startnode ? story.startnode : 1
    this.shortid = story.shortid
    this.likes = story.likes
    this.comments = story.comments || []
    this.avatar = avatar
    this.rotation = rotation

    this.checkLiked()
  }
  filename = ""
  contentname = ""
  shortid = null
  data = null
  likes = 0
  liked = false
  comments = []
  created = null
  story = null
  arrows = null
  activePassage = null
  setActivePassage = (passage) => this.activePassage = passage

  setLiked = (v) => this.liked = v
  checkLiked = (kistenId) => {
    const likes = ls.get('likes')
    if (likes && likes[kistenId]) {
      this.setLiked( likes[kistenId].includes(this.shortid) )
    } else {
      this.setLiked(false)
    }
  }

  get doIHaveToReload () {
    return this.data === null && this.created === null
  }

  addComment = (comment) => {
    this.comments.push(comment)
  }

  setComments = (c) => this.comments = c
  deleteCommentLocally = (id,cb) => {
    this.setComments( this.comments.filter(c => c.id != id) )
    if (cb) cb(this.comments)
  }

  likeIt = () => {
    this.likes++
  }
  dislikeIt = () => {
    this.likes = this.likes - 1 >= 0 ? this.likes - 1 : 0
  }

  correctPositionFromTopLeftTo = (story) => {
    const topCorrectedBy = 50
    const leftCorrectedBy = 20

    story.passages.map(passage => {
      passage.top += topCorrectedBy
      passage.left += leftCorrectedBy
      return passage
    })
  }

  load = (cb) => {
    if (this.doIHaveToReload) {
      AjaxClient.getStory(
        this.shortid,
        (res) => {
          if (res && res.data && res.data.success && res.data.story) {
            const { filename,contentname,shortid,data,created} = res.data.story
            this.filename = filename
            this.contentname = contentname
            this.shortid = shortid
            this.data = data
            this.created = created

            const stringified = Base64.decode(data)
            let story = JSON.parse(stringified)
            this.correctPositionFromTopLeftTo(story)

            this.findArrows(story.passages)
            this.story = story

            cb(this)
          }
        },
        () => {}
      )
    } else cb()
  }

  comment = (text,kistenId) => {
    if (text && text.trim() !== "" && kistenId && this.shortid) {
      AjaxClient.addStoryComment(
        { storyId: this.shortid, text, kistenId },
        () => {

        },
        () => {}
      )
    }
  }

  deleteComment = (commentId,kistenId) => {
    if (commentId && kistenId) {
      AjaxClient.deleteStoryComment(
        { commentId, storyId:this.shortid, kistenId },
        () => {

        },
        () => {}
      )
    }
  }

  like = (kistenId) => {
    let likes = ls.get('likes')
    if (!likes) likes = {}
    const LIKE = 1, DISLIKE = 2
    let type = LIKE

    if (likes[kistenId]) {
      if (likes[kistenId].includes(this.shortid)) {
        // DISLIKE
        likes[kistenId] = likes[kistenId].filter(id => id !== this.shortid)
        type = DISLIKE
      } else {
        // LIKE
        likes[kistenId].push(this.shortid)
        type = LIKE
      }
    } else {
      // LIKE
      likes[kistenId] = []
      likes[kistenId].push(this.shortid)
      type = LIKE
    }

    ls.set('likes',likes)

    AjaxClient.updateStoryLikes(
      { type, storyId: this.shortid, kistenId },
      () => {},
      () => {}
    )
  }

  findOptimalIframeSize = (passages) => {
    const minTop = passages.reduce((prev,curr) => { return prev.top < curr.top ? prev : curr })
    const maxTop = passages.reduce((prev,curr) => { return prev.top > curr.top ? prev : curr })
    const minLeft = passages.reduce((prev,curr) => { return prev.left < curr.left ? prev : curr })
    const maxLeft = passages.reduce((prev,curr) => { return prev.left > curr.left ? prev : curr })

    const size = {
      width: (parseInt(maxLeft.left)-parseInt(minLeft.left)+parseInt(minTop.width)+this.framePadding*2),
      height: (parseInt(maxTop.top)-parseInt(minTop.top)+parseInt(minTop.height)+this.framePadding),
      minLeft:minLeft.left,
      minTop:minTop.top

    }
    return size
  }

  findPassageByName = (name,passages) => {
    return passages.filter(p => p.name === name)[0]
  }

  findPassageByPid = (pid,passages) => {
    const p = passages.filter(p => p.pid == pid)
    return p && p[0] ? p[0] : null
  }

  markDoubleArrows = (arrows) => {
    let removeValFromIndex = []

    for (let i = 0; i < arrows.length; i++) {
      const arrow = arrows[i]
      for (let j = i+1; j < arrows.length; j++) {
        const connector = arrows[j]
        if (arrow.from === connector.to && connector.from === arrow.to) {
          arrow.options.showTail = true
          arrow.options.showHead = true
          removeValFromIndex.push(j)
          break;
        }
      }
    }

    arrows = arrows.filter((a,i) => !removeValFromIndex.includes(i))
    return arrows
  }

  markSelfArrows = (arrows,passages) => {
   return arrows.filter(arrow => {
     if (arrow.from === arrow.to) {
      arrow.options.self = true
      arrow.options.showTail = false
    } else {
      arrow.options.self = false
    }
    return arrow
   })
  }

  getSVGArrowPath = (p1,p2,flip = false) => {
    p1.height = parseInt(p1.height)
    p1.width = parseInt(p1.width)
    p2.height = parseInt(p2.height)
    p2.width = parseInt(p2.width)

    const arrow = getBoxToBoxArrow(
      p1.left,
      p1.top,
      p1.width,
      p1.height,
      p2.left,
      p2.top,
      p2.width,
      p2.height,
      {
        bow: 0.2,
        stretch: 0.5,
        stretchMin: 0,
        stretchMax: 0,
        padStart: 0,
        padEnd: 0,
        flip: flip,
        straights: true,
      }
    )

    const [sx, sy, cx, cy, ex, ey, ae, as, ec] = arrow
    return `M${sx},${sy} Q${cx},${cy} ${ex},${ey}`
  }

  addArrowOptions = (arrows,passages) => {
    arrows = arrows.map(arrow => {
      arrow.options = {}
      return arrow
    })
    arrows = this.markDoubleArrows(arrows)
    arrows = this.markSelfArrows(arrows,passages)

    arrows.map((arrow) => {
      if (arrow.options.self) {
        const passage = this.findPassageByPid(parseInt(arrow.from),passages)
        if (passage) {
          arrow.options.svgpath = `M${passage.left},${passage.top+40} A25,25 0 1 1 ${passage.left},${passage.top}`
        }
      } else {
        const p1 = this.findPassageByPid(parseInt(arrow.from),passages)
        const p2 = this.findPassageByPid(parseInt(arrow.to),passages)

        if (p1 && p2) {
          arrow.options.svgpath = this.getSVGArrowPath(p1,p2)
        }
      }
    })

    return arrows
  }

  findArrows = (passages) => {
    const arrows = []
    for (let i = 0; i < passages.length; i++) {
      const passage = passages[i]

      const regex = /\[\[[^\]]*\]\]/g
      const matches = passage.code.match(regex)
      if (matches && matches.length > 0 && matches[0]) {
        matches.map((match,index) => {
          let s = match
          s = s.substring(2,s.length - 2)
          s = s.split('->')
          s = s[s.length - 1]
          s = s.replace(/\[/g,'')
          s = s.replace(/\]/g,'')

          const connector = this.findPassageByName(s,passages)
          if (connector) {
            arrows.push({ from:passage.pid,to:connector.pid })
          }
        })
      }
    }
    this.arrows = this.addArrowOptions(arrows,passages)
    return passages
  }

}

class Archive {
  constructor (archive) {
    this.archive = archive
    makeAutoObservable(this)
  }

  selectedStory = null

  getNames = () => {
    return this.archive.map(s => s.getAttribute('name'))
  }

  select = (name) => {
    this.selectedStory = this.archive.filter(s => s.getAttribute('name') === name)[0]
  }

}

class Story {
  constructor (data) {
    this.kiste = data.kiste
    makeAutoObservable(this)
  }

  inputValue = ""
  setInputValue = (v) => this.inputValue = v
  loading = false
  toggleLoading = () => this.loading = !this.loading
  locked = true
  openLock = () => this.locked = false
  closeLock = () => this.locked = true
  kistenId = ""
  setKistenId = (kid) => this.kistenId = kid
  base64Story
  frame = { width: 0, height: 0 }
  setFrame = (f) => this.frame = f
  framePadding = 16
  contentname = ""
  setContentname = (name) => this.contentname = name
  startnode = "1"
  setStartnode = (node) => this.startnode = node
  filename = ""
  setFilename = (n) => this.filename = n
  archive = null

  lock = () => {
    this.setKistenId("")
    this.closeLock()
  }

  get unlocked () {
    return this.kistenId && this.kistenId !== '' && !this.locked
  }

  loadHTMLFile = (file) => {
    const reader = new FileReader();

    return new Promise(resolve => {
      reader.addEventListener('load', e => {
        resolve(e.target.result);
      });

      reader.readAsText(file, 'UTF-8');
    });
  }

  importHTMLFile = (files,cb) => {
    this.setFilename(files[0].name)
    this.loadHTMLFile(files[0]).then((html) => {
      this.convertTwineStory(html,cb)
    })
  }

  decryptPassage = (passage) => {
  	const pid = passage.getAttribute('pid')
  	const name = passage.getAttribute('name')
  	const position = passage.getAttribute('position').split(',')
  	const size = passage.getAttribute('size').split(',')
  	const code = passage.textContent

  	return {
      pid,
      name,
      left:parseFloat(position[0]),
      top:parseFloat(position[1]),
      width:parseFloat(size[0]),
      height:parseFloat(size[1]),
      code
    }
  }

  sanatizePassagePositions = (passages,frame) => {
    passages.map((p,index) => {
      p.left = (p.left - frame.minLeft < 0 ? 0 : p.left - frame.minLeft) + this.framePadding
      p.top = (p.top - frame.minTop < 0 ? 0 : p.top - frame.minTop) + this.framePadding/2
    })
    return passages
  }

  isArchive = (storyNodes) => { return storyNodes && storyNodes.length > 1 }
  findSimilarNames = (filename) => {
   return this.kiste.stories.filter(story => {
     const hasSimilarity = filename.indexOf(story.filename) >= 0 ? true : false
     if (hasSimilarity) {
       return { shortid: story.shortid, name:story.filename }
     }
   })
  }

  convertTwineStory = (html,cb) => {
  	let nodes = document.createElement('div')
  	nodes.innerHTML = html

  	const storyData = Array.from(nodes.querySelectorAll('tw-storydata'))
    const isArchive = this.isArchive(storyData)

    if (isArchive) {
     this.archive = new Archive(storyData)

     cb({ isArchive, archive:this.archive })
    } else {
      this.convertStoryNode(storyData[0],(d) => {
        cb(Object.assign({isArchive},d))
      })
    }
  }

  convertStoryNode = (storyNode,cb) => {
    const data = {}
    const contentname = storyNode.getAttribute('name')
    const startNode = storyNode.getAttribute('startnode')

    this.setContentname(contentname)
    this.setStartnode(startNode)

    const storyElements = Array.from(storyNode.querySelectorAll('tw-passagedata'))

    if (!storyElements || storyElements.length === 0) {
      return cb(false)
    }

    let passages = []
    for (let i = 0; i < storyElements.length; i++) {
      let passage = this.decryptPassage(storyElements[i])
      passages.push(passage)
    }

   const frame = this.findOptimalIframeSize(passages)
   this.setFrame(frame)
   passages = this.sanatizePassagePositions(passages,frame)

   const stringified = JSON.stringify({ passages,scrollTo:this.frame.upperPoint })
   this.base64Story = Base64.encode(stringified)

   // smart shit
   // const similarNames = this.findSimilarNames(this.filename)
   // if (similarNames && similarNames.length > 0) {
   //   // gibts schon
   //   data.update = true
   //   data.similarNames = similarNames
   // } else {
   //   // neu
   //   data.create = true
   // }

   data.create = true

   cb(data)
  }

  findOptimalIframeSize = (passages) => {
    let minTop = passages[0],
        maxTop = passages[0],
        minLeft = passages[0],
        maxLeft = passages[0]

    for (let i = 1; i < passages.length; i++) {
      const curr = passages[i]
      if (curr.top < minTop.top) minTop = curr
      if (curr.top > maxTop.top) maxTop = curr
      if (curr.left < minLeft.left) minLeft = curr
      if (curr.left > maxLeft.left) maxLeft = curr
    }

    const size = {
      width: (maxLeft.left-minLeft.left+minTop.width+this.framePadding*2),
      height: (maxTop.top-minTop.top+minTop.height+this.framePadding),
      minLeft:minLeft.left,
      minTop:minTop.top,
      maxLeft:maxLeft.left,
      maxTop:maxTop.top
    }

    return size
  }

  upload = (cb) => {
    const test = (this.base64Story && this.filename && this.contentname && this.kistenId) ? true : false
    if (test) {
      AjaxClient.createStory(
        { base64Story: this.base64Story, filename:this.filename, contentname: this.contentname, kiste:this.kistenId, startnode:this.startnode },
        (res) => {
          if (res && res.data.success) {
           if (cb) cb(true)
          }
        }
      )
    }
  }

  isValidPinFormat = (pin) => {
    return pin && pin.toString().length >= 6
  }

  unlock = (pin,cb) => {
    if (this.isValidPinFormat(pin)) {
      this.toggleLoading()

      AjaxClient.unlockKiste(
        pin,
        (res) => {
          if (res && res.data && res.data.success && res.data.shortid) {
           this.setKistenId(res.data.shortid)
          } else {
           this.setKistenId(null)
          }
          this.toggleLoading()
          let type = res && res.data && res.data.success ? res.data.type : -1
          cb(res.data.shortid,type,res.data.success)
         },
        () => {}
      )
    }
  }

  delete = (storyId,cb) => {
    AjaxClient.deleteStory(
      { kistenId: this.kistenId, storyId },
      (res) => {
        if (res && res.data && res.data.success) {
          cb(res.data.success)
        } else {
          cb(false)
        }
      },
      () => {}
    )
  }


}



const DataStore = createContext(new Data())
export default DataStore
